body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-noto-sans-cjk-kr: "Noto Sans CJK KR";
  --font-pretendard: Pretendard;

  /* font sizes */
  --font-size-sm: 14px;
  --font-size-3xl: 22px;
  --font-size-9xl: 28px;
  --font-size-21xl: 40px;
  --font-size-51xl: 70px;
  --font-size-lg: 18px;
  --font-size-base: 16px;

  /* Colors */
  --color-white: #fff;
  --color-gainsboro: #e2e2e2;
  --color-lightgray: #cecfcf;
  --color-black: #000;
  --color-cornflowerblue-100: #1aa8ff;
  --color-cornflowerblue-200: #0a98ef;
  --color-dimgray: #666;

  /* Border radiuses */
  --br-5xl: 24px;
  --br-5xs: 8px;
  --br-12xs: 1px;
}
